type ExtensionsWithErrorCode = {
  errorCodes: string[];
};

/**
 * Callback for use when filtering an array to return only those with an array
 * of errorCodes.
 * @param ext extensions as provided
 * @returns
 */
export const hasErrorCodes = (ext: {
  [key: string]: any;
}): ext is ExtensionsWithErrorCode => Array.isArray(ext.errorCodes);
