import { Flex } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';

type Omitted = 'display' | 'dir' | 'flexDir' | 'flexDireection';

type RowProps = Omit<FlexProps & { reverse?: boolean }, Omitted>;

/**
 * Row. Accepts same props as Charkra's Flex excluding display and flex
 * direction props. Refer to usage below for guide on setting widths if you need
 * multiple next to each other.
 *
 * ```tsx
 * <Row
 *   width={{ base: '100%', md: '50%', lg: '25%' }}
 * >
 *   <OtherComponents />
 * </Row>
 * ```
 */
export const Row = ({
  children,
  reverse = false,
  ...rest
}: RowProps): JSX.Element => {
  const flexDir = reverse ? 'row-reverse' : 'row';
  return (
    <Flex flexDir={flexDir} {...rest}>
      {children}
    </Flex>
  );
};
