import React from 'react';
import {
  Box,
  Flex,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';
import { motion, useCycle } from 'framer-motion';
import { IoIosClose, IoIosMenu } from 'react-icons/io';
import { VintraceHorizontalMono, VintraceSingleMono } from '../logos';
import { Overlay, Row } from '../_layout';
import { WithChildren } from '../types';

interface Props {
  titleText?: string;
  showBurger?: boolean;
}

// TODO - Navigation should be contained in a Chakra Drawer component, instead of this custom built section.
// Ref: https://chakra-ui.com/docs/components/overlay/drawer
export const Navigation = ({
  children,
  titleText,
  showBurger = true,
}: WithChildren<Props>): JSX.Element => {
  const { colors } = useTheme();
  // todo :: when storybook updates to @emotion/* >= 11 we can go back to using
  // todo :: strings instead of the `useTheme` hook
  // const bg = useColorModeValue('teal.600', 'teal.900');
  const bg = useColorModeValue(colors.teal[600], colors.teal[900]);
  const burgerDisplay = useBreakpointValue({
    base: showBurger ? 'block' : 'none',
    md: 'none',
  });

  const animatedDivPosition = useBreakpointValue({
    base: 'absolute' as const,
    md: 'relative' as const,
  });

  const slideClosedValue = useBreakpointValue({
    base: { opacity: 0, left: -500 },
    md: { opacity: 1, left: 0 },
  });

  const [slide, cycleSlide] = useCycle(slideClosedValue, {
    opacity: 1,
    left: 0,
  });

  // todo find more efficient way to do this.
  const animatedDivValues = useBreakpointValue({
    // NOTE: slide *might* be undefined so we provide the default, defined value
    // for it. Without this, the nav will be visible until closed on mobile.
    base: slide ?? slideClosedValue,
    md: { opacity: 1, left: 0 },
  });

  const canShowOverlay = useBreakpointValue({ base: true, md: false });

  const minHeight = useBreakpointValue({ base: '100vh', md: 'unset' });

  const isVisible =
    (showBurger && canShowOverlay && (animatedDivValues?.left ?? -1) >= 0) ??
    false;
  return (
    <Row
      bg={bg}
      as="nav"
      alignItems="center"
      px={{ base: '0.75rem', md: '1rem' }}
    >
      <Overlay isOpen={isVisible} zIndex={3} />
      <Box
        as={IoIosMenu}
        size="1.5em"
        onClick={() => cycleSlide()}
        display={burgerDisplay}
        color="white"
        mr="0.75rem"
      />
      <Box py="0.5rem" pr="0.75rem">
        <VintraceSingleMono fill="#fff" />
      </Box>
      {titleText && (
        <Text size="1.25rem" color="white" alignSelf="center" mr={4}>
          {titleText}
        </Text>
      )}
      <motion.div
        animate={animatedDivValues}
        style={{
          position: animatedDivPosition,
          top: 0,
          bottom: 0,
          zIndex: 3,
          minHeight,
        }}
      >
        <Box
          background={{ base: 'white', md: 'unset' }}
          flexDir={{ base: 'column', md: 'row' }}
          height={{ base: '100%', md: 'unset' }}
          width={{ base: '20rem', md: 'auto' }}
          position={{ base: 'absolute', md: 'relative' }}
          display="flex"
          flex={1}
          zIndex={4}
        >
          <Flex
            minHeight="5.25rem"
            background="teal.500"
            pt="2rem"
            pb="0.625rem"
            px="0.75rem"
            alignItems="center"
            justifyContent="space-between"
            display={{ base: 'flex', md: 'none' }}
          >
            <VintraceHorizontalMono />
            <IoIosClose onClick={() => cycleSlide()} size="2em" color="white" />
          </Flex>
          {children}
        </Box>
      </motion.div>
    </Row>
  );
};
