import { forwardRef } from 'react';
import { Input } from '@chakra-ui/react';
import type { InputProps } from './InputTypes';
import { inputBaseStyle } from './inputBaseStyle';

export const TextInput = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => (
    <Input
      ref={ref}
      type="text"
      width="100%"
      // paddingLeft="10px"
      {...inputBaseStyle}
      {...props}
    />
  ),
);
