export enum SiwoErrorCode {
  OKTA_INVALID_CREDENTIALS = 'OKTA_INVALID_CREDENTIALS',
  USER_CLAIM_NO_EMAIL = 'USER_CLAIM_NO_EMAIL',
  OKTA_NOT_CONFIGURED = 'OKTA_NOT_CONFIGURED',
  OKTA_AUTH_FAILED = 'OKTA_AUTH_FAILED',
}

export const siwoErrorCodesObj = Object.keys(SiwoErrorCode).reduce(
  (acc, curr) => {
    return {
      ...acc,
      [curr]: curr.toString(),
    };
  },
  {} as Record<string, string>,
);

export const siwoErrorArray = Object.values(SiwoErrorCode);
