/**
 * Get the API base URL, handles local environments. Returned URL does not
 * include a trailing slash.
 * @returns api base URL
 */
export const getApiBase = (): string => {
  const isLocal = window.location.host.includes(':3000');
  const local = `${window.location.hostname}:3000`;
  const url = isLocal ? local : window.location.hostname;
  return `https://${url}/api`;
};
