import { Spinner } from '@chakra-ui/react';
import { MediumText } from '../text/MediumText';

interface Props {
  title: string;
  description: string;
}

export const AuthSpinner = ({ title, description }: Props): JSX.Element => {
  return (
    <>
      <MediumText fontSize="1.75rem" text={title} my="0.75rem" />
      <Spinner
        size="xl"
        color="teal.500"
        thickness="2px"
        emptyColor="gray.200"
        speed="0.65s"
      />
      <MediumText my="0.75rem" color="gray.500" text={description} />
    </>
  );
};
