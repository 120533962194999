import { CentralAuthErrorCode } from './CentralAuthErrors';

export const customerCodeErrorCodes = [
  CentralAuthErrorCode.INVALID_CUSTOMER_CODE,
];

export const emailErrorCodes = [
  CentralAuthErrorCode.INVALID_EMAIL,
  CentralAuthErrorCode.USER_NOT_FOUND,
  CentralAuthErrorCode.NO_ACCESS_TO_APP,
  CentralAuthErrorCode.INVALID_CREDENTIALS,
  CentralAuthErrorCode.REGISTRATION_BY_INVITE_ONLY,
  CentralAuthErrorCode.UNSUPPORTED_AUTHENTICATION_METHOD,
];

export const passwordErrorCodes = [
  CentralAuthErrorCode.PASSWORD_REQUIRED,
  CentralAuthErrorCode.NO_ACCESS_TO_APP,
  CentralAuthErrorCode.INVALID_CREDENTIALS,
];
