import React, { useMemo } from 'react';
import {
  FormControl,
  VStack,
  Button,
  useToast,
  FormErrorMessage,
  Link,
} from '@chakra-ui/react';
import {
  Label,
  LightText,
  NormalText,
  PasswordInput,
  errorsContainsOneOf,
} from '@carafe/components';
import { strings } from '@localisation';
import { useForm } from 'react-hook-form';
import { useChangePasswordMutation } from '@generated/graphql';
import { ProfileManagementErrorCode } from '@carafe/errors';
import { errorCodeParser, messageForCodes } from '@errors';

type FormData = {
  currentPassword: string;
  newPassword: string;
  confirm: string;
};

declare const CENTRAL_AUTH_DOMAIN: string;
const centralAuthUrl = `https://${CENTRAL_AUTH_DOMAIN}`;

export const ChangePasswordForm = (): JSX.Element => {
  const { register, handleSubmit, reset, watch } = useForm<FormData>({
    mode: 'onBlur',
  });

  const [{ fetching, error }, changePassword] = useChangePasswordMutation();

  const toast = useToast();
  const { confirm, newPassword, currentPassword } = watch();

  const onSubmit = (formData: FormData) => {
    changePassword({
      data: {
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword,
      },
    })
      .then(({ error }) => {
        if (error) {
          // TODO - Datadog log
          console.error('ChangePasswordForm (urql):', { error });
          toast({
            title: strings.toast.error.title,
            description: strings.toast.error.description,
            status: 'error',
          });
          return;
        }
        reset();
        toast({
          title: strings.toast.title,
          description: strings.toast.description,
          status: 'success',
        });
      })
      .catch((error) => {
        // TODO - Datadog log
        console.error('ChangePasswordForm (catch):', { error });
      });
  };

  const errors = useMemo(() => errorCodeParser(error?.graphQLErrors), [error]);

  const isDisabled =
    !currentPassword?.length ||
    !confirm?.length ||
    !newPassword?.length ||
    confirm !== newPassword;
  const noMatch = isDisabled && confirm !== newPassword;
  const isValid = !noMatch && !isDisabled;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack alignItems="flex-start" spacing={4} minHeight="15rem">
        <LightText text={strings.password.title} fontSize="1.25rem" />

        <FormControl
          isInvalid={errorsContainsOneOf(errors, [
            ProfileManagementErrorCode.INCORRECT_PASSWORD,
          ])}
        >
          <Label htmlFor="current" isRequired>
            {strings.password.current}
          </Label>
          <PasswordInput
            id="current"
            name="currentPassword"
            ref={register({ required: true })}
          />
          <FormErrorMessage fontSize="0.75rem">
            <NormalText text={strings.error.INCORRECT_PASSWORD} />
          </FormErrorMessage>
        </FormControl>
        <FormControl display="flex">
          <Link
            fontWeight="500"
            fontSize="0.875rem"
            isExternal
            href={`${centralAuthUrl}/forgot-password`}
          >
            {strings.password.forgotYourPassword}
          </Link>
        </FormControl>
        <FormControl
          isInvalid={errorsContainsOneOf(errors, [
            ProfileManagementErrorCode.PASSWORD_TOO_SHORT,
            ProfileManagementErrorCode.PASSWORD_TOO_LONG,
          ])}
        >
          <Label htmlFor="new" isRequired>
            {strings.password.new}
          </Label>
          <PasswordInput
            id="new"
            name="newPassword"
            isValid={isValid}
            ref={register({
              required: true,
            })}
          />
          <FormErrorMessage fontSize="0.75rem">
            <NormalText
              text={
                messageForCodes(errors, [
                  ProfileManagementErrorCode.PASSWORD_TOO_SHORT,
                  ProfileManagementErrorCode.PASSWORD_TOO_LONG,
                ]) ?? strings.error.PASSWORD_TOO_SHORT
              }
            />
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={noMatch}>
          <Label htmlFor="confirm" isRequired>
            {strings.password.confirm}
          </Label>
          <PasswordInput
            id="confirm"
            name="confirm"
            isValid={isValid}
            ref={register({ required: true })}
          />
          <FormErrorMessage fontSize="0.75rem">
            <NormalText text={strings.error.PASSWORDS_DONT_MATCH} />
          </FormErrorMessage>
        </FormControl>
      </VStack>
      <FormControl
        display="flex"
        justifyContent="flex-end"
        mt={{ base: '1rem', md: '1.5rem' }}
      >
        <Button
          variant="solid"
          colorScheme="teal"
          backgroundColor="teal.600"
          type="submit"
          isLoading={fetching}
          isDisabled={isDisabled}
        >
          {strings.form.changePassword}
        </Button>
      </FormControl>
    </form>
  );
};
