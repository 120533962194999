import { Col, MediumText, NormalText } from '@carafe/components';
import {
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { Avatar } from '@components/avatar';
import { DetailsForm } from '@components/details';
import { ChangePasswordForm } from '@components/password';
import { useGetProfileQuery } from '@generated/graphql';
import { strings } from '@localisation';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

export const Profile = (): JSX.Element => {
  const { pathname } = useLocation();
  const [tabIndex, setTabIndex] = useState(0);
  const [{ data, fetching }] = useGetProfileQuery();

  // When cpw is the value of the tab query string, set the tab index to 1 so
  // the user will default to seeing the change password tab.
  useEffect(() => {
    if (pathname.includes('change-password')) {
      setTabIndex(1);
    }
  }, [pathname]);

  return (
    <Col flex={1} mx={{ base: '0.75rem' }}>
      <MediumText
        text={strings.title}
        fontSize="0.875rem"
        color="gray.600"
        mb={2}
      />
      <Flex
        py="1rem"
        px="1.75rem"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="10px"
        flex={1}
      >
        <Tabs
          colorScheme="teal"
          width="100%"
          index={tabIndex}
          onChange={(index) => setTabIndex(index)}
        >
          <TabList>
            <Tab _selected={{ color: 'black', borderColor: 'teal.600' }}>
              <NormalText fontSize="0.875rem" text={strings.tabs.details} />
            </Tab>
            <Tab _selected={{ color: 'black', borderColor: 'teal.600' }}>
              <NormalText fontSize="0.875rem" text={strings.tabs.password} />
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel px="0" pb="0">
              <Avatar
                firstName={data?.getProfile.firstName}
                lastName={data?.getProfile.lastName}
                avatar={data?.getProfile.avatar}
              />
              <DetailsForm fetching={fetching} profile={data?.getProfile} />
            </TabPanel>
            <TabPanel px="0" pb="0">
              <ChangePasswordForm />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Col>
  );
};
