export enum ProfileManagementErrorCode {
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  FIRST_NAME_REQUIRED = 'FIRST_NAME_REQUIRED',
  LAST_NAME_REQUIRED = 'LAST_NAME_REQUIRED',
  PHONE_NUMBER_REQUIRED = 'PHONE_NUMBER_REQUIRED',
  EMAIL_IN_USE = 'EMAIL_IN_USE',
  INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
  PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
  PASSWORD_TOO_LONG = 'PASSWORD_TOO_LONG',
  CURRENT_PASSWORD_REQUIRED = 'CURRENT_PASSWORD_REQUIRED',
  TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  PASSWORDS_DONT_MATCH = 'PASSWORDS_DONT_MATCH',
}

export const profileManagementErrorCodesObj = Object.keys(
  ProfileManagementErrorCode,
).reduce((acc, curr) => {
  return {
    ...acc,
    [curr]: curr.toString(),
  };
}, {} as Record<string, string>);

export const profileManagementErrorArray = Object.values(
  ProfileManagementErrorCode,
);
