export enum SiwaErrorCode {
  APPLE_SIGN_IN_FAILED = 'APPLE_SIGN_IN_FAILED',
  USER_CANCELLED_AUTHORIZE = 'USER_CANCELLED_AUTHORIZE',
  APPLE_ID_IN_USE = 'APPLE_ID_IN_USE',
  APPLE_ID_NOT_FOUND = 'APPLE_ID_NOT_FOUND',
}

export const siwaErrorCodesObj = Object.keys(SiwaErrorCode).reduce(
  (acc, curr) => {
    return {
      ...acc,
      [curr]: curr.toString(),
    };
  },
  {} as Record<string, string>,
);

export const siwaErrorArray = Object.values(SiwaErrorCode);
