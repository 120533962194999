import type { GraphQLError } from 'graphql';
import { hasErrorCodes } from './hasErrorCodes';
import { hasExtensions } from './hasExtensions';

/**
 * Search through the errors for any error codes we're seeking.
 * @param gqlErrors array of graphql errors as provided by urql via
 * `error?.graphQLErrors[]`
 * @returns
 */
export const parseErrorCodes = (gqlErrors?: GraphQLError[]): string[] => {
  if (!gqlErrors) {
    return [];
  }

  const errorCodes = gqlErrors
    .filter(hasExtensions)
    .map((err) => err.extensions)
    .filter(hasErrorCodes)
    .map((h) => h.errorCodes)
    .flat();

  return errorCodes;
};

/**
 * Returns a function for checking that a given value exists in an enum.
 * @param enumVariable any given enum
 * @returns
 */
function createEnumChecker<T extends string, TEnumValue extends string>(
  enumVariable: { [key in T]: TEnumValue },
) {
  const enumValues = Object.values(enumVariable);
  return (value: string): value is TEnumValue => enumValues.includes(value);
}

/**
 * Returns a function for parsing error codes as returned via GraphQL, ensures
 * only known and handled error codes are utlimtely returned.
 * @param codes enum of error codes
 * @returns
 */
export const createCodeParser = <T extends string, TEnumValue extends string>(
  codes: { [key in T]: TEnumValue },
): ((gqlErrors?: GraphQLError[]) => TEnumValue[]) => {
  const enumChecker = createEnumChecker(codes);

  const parseErrorCodes = (gqlErrors?: GraphQLError[]) => {
    if (!gqlErrors) {
      return [];
    }

    const errorCodes = gqlErrors
      .filter(hasExtensions)
      .map((err) => err.extensions)
      .filter(hasErrorCodes)
      .map((h) => h.errorCodes)
      .flat()
      .filter(enumChecker);

    return errorCodes;
  };
  return parseErrorCodes;
};
