import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormErrorMessage,
  PopoverBody,
  PopoverFooter,
  Button,
  PopoverHeader,
  IconButton,
  PopoverContent,
  PopoverArrow,
  Portal,
  PopoverTrigger,
  Flex,
  Popover,
} from '@chakra-ui/react';
import {
  TextInput,
  Label,
  NormalText,
  PasswordInput,
  MediumText,
  Overlay,
  errorsContainsOneOf,
} from '@carafe/components';
import { ProfileManagementErrorCode } from '@carafe/errors';
import { strings } from '@localisation';
import { useProfileManagement } from '@hooks';
import { IoCloseOutline, IoCreateOutline } from 'react-icons/io5';
import { messageForCodes } from '@errors';

interface Props {
  email?: string;
}

export const EmailForm = ({ email }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);
  const [newEmail, setNewEmail] = useState('');
  const [password, setPassword] = useState('');

  const { changeEmailErrorCodes, onChangeEmail, emailChanging, emailChanged } =
    useProfileManagement();

  // Reset state and close modal when email changed successfully
  useEffect(() => {
    if (emailChanged) {
      setNewEmail('');
      setPassword('');
      close();
    }
  }, [emailChanged]);

  return (
    <>
      <Overlay isOpen={isOpen} />
      <Popover placement="top" isOpen={isOpen} onClose={close}>
        <PopoverTrigger>
          <Flex
            color="teal.600"
            fontSize="0.875rem"
            alignItems="center"
            onClick={open}
            py="0.5rem"
            display={{ base: 'flex', md: 'inline-flex' }}
          >
            <MediumText text={email ?? ''} mr={1} />
            <IoCreateOutline size="1rem" />
          </Flex>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <NormalText fontSize="0.875rem" text={strings.form.updateEmail} />
              <IconButton
                aria-label="close"
                fontSize="1.5rem"
                icon={<IoCloseOutline />}
                size="sm"
                variant="ghost"
                onClick={close}
              />
            </PopoverHeader>
            <PopoverBody>
              <FormControl
                isInvalid={errorsContainsOneOf(changeEmailErrorCodes, [
                  ProfileManagementErrorCode.EMAIL_IN_USE,
                ])}
              >
                <Label htmlFor="newEmail" isRequired>
                  {strings.form.newEmail}
                </Label>
                <TextInput
                  name="newEmail"
                  id="newEmail"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                />
                <FormErrorMessage fontSize="0.75rem">
                  <NormalText
                    text={
                      messageForCodes(changeEmailErrorCodes, [
                        ProfileManagementErrorCode.EMAIL_IN_USE,
                      ]) ?? strings.error.EMAIL_IN_USE
                    }
                  />
                </FormErrorMessage>
              </FormControl>

              <FormControl
                mt={4}
                isInvalid={errorsContainsOneOf(changeEmailErrorCodes, [
                  ProfileManagementErrorCode.INCORRECT_PASSWORD,
                  ProfileManagementErrorCode.CURRENT_PASSWORD_REQUIRED,
                ])}
              >
                <Label htmlFor="confirmPassword" isRequired>
                  {strings.form.confirmPassword}
                </Label>
                <PasswordInput
                  name="confirmPassword"
                  id="confirmPassword"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FormErrorMessage fontSize="0.75rem">
                  <NormalText
                    text={
                      messageForCodes(changeEmailErrorCodes, [
                        ProfileManagementErrorCode.INCORRECT_PASSWORD,
                        ProfileManagementErrorCode.CURRENT_PASSWORD_REQUIRED,
                      ]) ?? strings.error.INCORRECT_PASSWORD
                    }
                  />
                </FormErrorMessage>
              </FormControl>
            </PopoverBody>
            <PopoverFooter
              borderTop="none"
              display="flex"
              justifyContent="flex-end"
              py={4}
            >
              <Button
                size="sm"
                colorScheme="teal"
                isLoading={emailChanging}
                onClick={() => onChangeEmail(newEmail, password)}
                isDisabled={!password.length}
              >
                {strings.form.update}
              </Button>
              <Button
                size="sm"
                colorScheme="teal"
                variant="ghost"
                onClick={close}
              >
                {strings.form.cancel}
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
};
