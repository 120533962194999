export enum SiwgErrorCode {
  GOOGLE_ID_IN_USE = 'GOOGLE_ID_IN_USE',
  GOOGLE_ID_NOT_FOUND = 'GOOGLE_ID_NOT_FOUND',
}

export const siwgErrorCodesObj = Object.keys(SiwgErrorCode).reduce(
  (acc, curr) => {
    return {
      ...acc,
      [curr]: curr.toString(),
    };
  },
  {} as Record<string, string>,
);

export const siwgErrorArray = Object.values(SiwgErrorCode);
