import { Text } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';

type Omitted = 'fontWeight';

export type LightTextProps = Omit<BoxProps, Omitted> & {
  text: string | number;
};

export const LightText = ({ text, ...rest }: LightTextProps): JSX.Element => (
  <Text fontWeight="300" {...rest}>
    {text}
  </Text>
);
