import { Flex } from '@chakra-ui/react';
import { WithChildren } from '@carafe/components';

export const Centered = ({ children }: WithChildren): JSX.Element => (
  <Flex
    flexDir={{ base: 'column', md: 'row' }}
    minHeight="100vh"
    justify="center"
    align="center"
    width="100%"
  >
    {children}
  </Flex>
);
