import { useMemo, useEffect } from 'react';
import { useChangeEmailMutation } from '@generated/graphql';
import { useToast } from '@chakra-ui/react';
import { strings } from '@localisation';
import { errorCodeParser } from '@errors';
import { ProfileManagementErrorCode } from '@carafe/errors';

interface UseProfileManagement {
  onChangeEmail: (newEmail: string, password: string) => void;
  changeEmailErrorCodes: ProfileManagementErrorCode[];
  emailChanging?: boolean;
  emailChanged?: boolean;
}

export const useProfileManagement = (): UseProfileManagement => {
  const [
    { data: emailData, fetching: emailChanging, error: changeEmailError },
    changeEmail,
  ] = useChangeEmailMutation();

  const toast = useToast();

  const changeEmailErrorCodes = useMemo(
    () => errorCodeParser(changeEmailError?.graphQLErrors),
    [changeEmailError?.graphQLErrors],
  );

  useEffect(() => {
    if (emailData?.changeEmail) {
      toast({
        title: strings.toast.title,
        description: strings.toast.description,
        status: 'success',
      });
      close();
    }
  }, [emailData, toast]);

  const onChangeEmail = (newEmail: string, password: string) => {
    changeEmail({ data: { newEmail, password } });
  };

  return {
    onChangeEmail,
    changeEmailErrorCodes,
    emailChanging,
    emailChanged: emailData?.changeEmail,
  };
};
