import { Redirect, useLocation } from 'react-router-dom';
import { Routes } from './';
import { useQueryString, WithChildren, useAuth } from '@carafe/components';

/**
 * Route guard for protected routes. Checks for an access token in redux and
 * redirects to sign in if none found.
 */
export const ProtectedRoutes = ({ children }: WithChildren): JSX.Element => {
  const { isSignedIn, isSigningIn } = useAuth();
  const { pathname } = useLocation();
  const [code] = useQueryString(['code']);

  if (code && !isSignedIn) {
    const to = `${Routes.Home}?code=${code}&?redirectTo=${pathname}`;
    return <Redirect to={to} />;
  }

  if (isSigningIn) {
    return <></>;
  }

  if (!isSignedIn) {
    return <Redirect to={Routes.Unauthorized} />;
  }
  return <>{children}</>;
};
