import {
  Flex,
  Input,
  Box,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useToast,
  IconButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/react';
import { ChangeEvent, Col, NormalText } from '@carafe/components';
import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { MdRotate90DegreesCcw } from 'react-icons/md';
import { IoCloseOutline, IoCloudUploadOutline } from 'react-icons/io5';
import { GrZoomIn } from 'react-icons/gr';
import {
  useGetProfilePhotoUploadUrlMutation,
  useUpdateAvatarUrlMutation,
} from '@generated/graphql';
import { strings } from '@localisation';
import { useEffect } from 'react';

interface Props {
  onClose: () => void;
}

const MIN_SCALE = 0.5;
const MAX_SCALE = 3;

export const PhotoPicker = ({ onClose }: Props): JSX.Element => {
  const [photo, setPhoto] = useState<File>();
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [scale, setScale] = useState(1.0);
  const [rotate, setRotate] = useState(0);
  const editor = useRef<AvatarEditor>(null);
  const [{ fetching: urlFetching }, go] = useGetProfilePhotoUploadUrlMutation();
  const [{ data, fetching }, updateAvatarUrl] = useUpdateAvatarUrlMutation();
  const toast = useToast();

  useEffect(() => {
    if (data?.updateAvatarUrl) {
      toast({
        title: strings.toast.title,
        description: strings.toast.description,
        status: 'success',
      });
    }
  }, [data?.updateAvatarUrl, toast]);

  const onSelect = (e: ChangeEvent) => {
    if (!e.target.files?.length) {
      return;
    }
    const file = e.target.files[0];
    setPhoto(file);
  };

  const onSave = async () => {
    editor.current?.getImageScaledToCanvas().toBlob((blob) => {
      if (blob) {
        handleUpload(blob);
      }
    });
  };

  const handleUpload = async (img: Blob) => {
    setUploadingPhoto(true);
    const { data } = await go();
    if (!data?.getProfilePhotoUploadUrl) {
      return;
    }

    try {
      const resp = await fetch(data.getProfilePhotoUploadUrl.uploadUrl, {
        method: 'PUT',
        body: img,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      });
      if (resp.status !== 200) {
        return;
      }

      const a = await updateAvatarUrl({
        filename: data.getProfilePhotoUploadUrl.filename,
      });

      if (a.data?.updateAvatarUrl) {
        setUploadingPhoto(false);
        onClose();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onRotate = () => {
    const newRotate = rotate + 90;
    if (newRotate === 360) {
      setRotate(0);
    } else {
      setRotate(newRotate);
    }
  };

  const title = photo ? strings.avatar.editAndSave : strings.avatar.uploadPhoto;
  return (
    <>
      <ModalHeader px={2} py={3} display="flex" alignItems="center">
        <IoCloseOutline size="1.5rem" onClick={onClose} />
        <NormalText ml={3} fontSize="1rem" text={title} />

        <IconButton
          ml="auto"
          mr={2}
          padding="0.5rem"
          height="2rem"
          minWidth="unset"
          borderRadius="50%"
          backgroundColor="gray.200"
          aria-label={strings.avatar.rotate}
          icon={<MdRotate90DegreesCcw />}
          onClick={onRotate}
          isDisabled={!photo}
        />
      </ModalHeader>
      <ModalBody p={0}>
        <Box bgColor="#565757" py={photo ? 'none' : '1rem'}>
          {!photo ? (
            <>
              <Flex
                mx="auto"
                border="1px dotted"
                borderColor="white"
                width="18rem"
                height="18rem"
                as="label"
                htmlFor="avatar"
                borderRadius="50%"
                alignItems="center"
                justifyContent="center"
                color="white"
              >
                <IoCloudUploadOutline size="3rem" />
              </Flex>
              <Input
                display="none"
                type="file"
                id="avatar"
                onChange={onSelect}
                accept="image/png, image/jpeg"
                multiple={false}
              />
            </>
          ) : (
            <Col position="relative">
              <AvatarEditor
                ref={editor}
                borderRadius={100}
                border={0}
                rotate={rotate}
                scale={scale}
                image={photo}
                style={{
                  width: 360,
                  height: 360,
                  margin: '0 auto',
                }}
              />
              <Box
                width="22.5rem"
                height="22.5rem"
                position="absolute"
                border="2px solid"
                borderColor="white"
                borderRadius="50%"
                zIndex={1}
                style={{ isolation: 'isolate' }}
                pointerEvents="none"
              />
            </Col>
          )}
        </Box>
        <Flex px={8}>
          <Slider
            min={MIN_SCALE}
            max={MAX_SCALE}
            value={scale}
            step={0.1}
            m={0}
            width="100%"
            minH="4rem"
            aria-label="zoom image slider"
            size="lg"
            onChange={(value) => setScale(value)}
          >
            <SliderTrack>
              <SliderFilledTrack bg="teal.500" />
            </SliderTrack>
            <SliderThumb
              boxShadow="0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)"
              boxSize={8}
            >
              <GrZoomIn size="1.125rem" />
            </SliderThumb>
          </Slider>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button
          colorScheme="teal"
          bg="teal.600"
          mr={3}
          onClick={onSave}
          isDisabled={!photo}
          isLoading={urlFetching || fetching || uploadingPhoto}
        >
          {strings.form.save}
        </Button>
        <Button colorScheme="teal" variant="ghost" onClick={onClose}>
          {strings.form.cancel}
        </Button>
      </ModalFooter>
    </>
  );
};
