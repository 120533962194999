import { Text } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';

type Omitted = 'fontWeight';

export type NormalTextProps = Omit<BoxProps, Omitted> & {
  text: string | number;
};

export const NormalText = ({ text, ...rest }: NormalTextProps): JSX.Element => (
  <Text fontWeight="600" {...rest}>
    {text}
  </Text>
);
