import { Flex } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';

type Omitted = 'dir' | 'flexDir' | 'flexDireection';

type ColProps = Omit<FlexProps, Omitted>;

/**
 * Column. Accepts same props as Charkra's Flex excluding display and flex
 * direction props. Refer to usage below for guide on setting widths if you need
 * multiple next to each other.
 *
 * ```tsx
 * <Col
 *   width={{ base: '100%', md: '50%', lg: '25%' }}
 * >
 *   <OtherComponents />
 * </Col>
 * ```
 */
export const Col = ({ children, ...rest }: ColProps): JSX.Element => (
  <Flex flexDir="column" {...rest}>
    {children}
  </Flex>
);
