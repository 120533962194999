import { extendTheme } from '@chakra-ui/react';
import type { ChakraTheme } from '@chakra-ui/react';

interface Gradients {
  teal: string;
}
interface BoxShadow {
  default: string;
}

// The Shadows type is not exported so we need to be creative
type BaseShadows = ChakraTheme['shadows'];

// Create our own type for shadows so we can appropriate Tailwind's
type Shadows = BaseShadows & { box: string };

/**
 * Extensions to the base Chakra Theme are declared here.
 */
export interface Theme extends ChakraTheme {
  gradients: Gradients;
  boxShadow: BoxShadow;
  shadows: Shadows;
}

const gradients = {
  teal: 'linear-gradient(90deg, #00ADA8 0%, #00D2CE 100%)',
};

// TODO - Inspect the following shadow formulas
const shadows = {
  xs: '0 0 0 1px rgba(0, 0, 0, 0.05);',
  sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05);',
  box: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);',
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);',
  xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);',
  '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25);',
  inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);',
  outline: '0 0 0 3px rgba(66, 153, 225, 0.5);',
  none: 'none',
};
const fontFamily = 'Montserrat, Helvetica Neue, sans-serif';

/**
 * Our own theme which overrides the default Chakra theme.
 */
// TODO - Inspect the following theme spec
export const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        WebkitFontSmoothing: 'subpixel-antialiased',
        MozOsxFontSmoothing: 'grayscale',
        fontFamily,
      },
      a: { color: 'teal.500' },
    },
  },
  colors: {
    gray: {
      '25': '#FBFBFC',
      '75': '#F0F1F4',
    },
    blue: {
      '550': '#007DC1',
    },
  },
  fonts: { body: fontFamily, heading: fontFamily },
  shadows,
  gradients,
  boxShadow: { default: '0px 1px 3px rgba(0, 0, 0, 0.05)' },
  components: {
    Tabs: {
      defaultProps: {
        colorScheme: 'teal',
      },
    },
    Button: {
      baseStyle: {
        fontWeight: '500',
      },
    },
    Link: {
      baseStyle: {
        color: 'teal.600',
      },
    },
  },
});
