import { createContext } from 'react';

export interface AuthContextInterface {
  isSignedIn: boolean;
  isSigningIn: boolean;
}

const initialContext: AuthContextInterface = {
  isSignedIn: false,
  isSigningIn: false,
};

/**
 * The Auth Context
 */
export const AuthContext = createContext<AuthContextInterface>(initialContext);
