import { IoWarningOutline } from 'react-icons/io5';
import { Col, LightText, useQueryString } from '@carafe/components';
import { Centered } from '@layout';
import { strings } from '@localisation';
import { ProfileManagementErrorCode } from '@carafe/errors';

const parseError = (errorId: string | null) => {
  if (errorId !== null && errorId in ProfileManagementErrorCode) {
    return errorId as ProfileManagementErrorCode;
  }
  return ProfileManagementErrorCode.TOKEN_NOT_FOUND;
};

export const Unauthorized = (): JSX.Element => {
  const [errorId] = useQueryString(['errorId']);
  const error = parseError(errorId);
  const message = strings.error[error];

  return (
    <Centered>
      <Col alignItems="center">
        <IoWarningOutline size="2rem" />
        <LightText
          py="1rem"
          textAlign="center"
          maxWidth={{ base: '95%', md: '30rem' }}
          text={message}
          fontSize="1.75rem"
        />
      </Col>
    </Centered>
  );
};
