import { ProfileManagementErrorCode } from '@carafe/errors';
import LocalizedStrings from 'react-localization';
import en from './en.json';
import es from './es.json';
import it from './it.json';

type ErrorStrings = {
  error: Record<ProfileManagementErrorCode, string>;
};

type StringStructure = Omit<typeof en, 'error'> & ErrorStrings;

/**
 * This is here so that should we forget to include any of the error strings in
 * our primary language file, TypeScript will bark at us very loudly.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _checkEn: StringStructure = en;

export const strings = new LocalizedStrings(
  {
    en,
    es: es as StringStructure,
    it: it as StringStructure,
  },
  {
    logsEnabled: process.env.NODE_ENV === 'development',
  },
);
