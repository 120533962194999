import { Flex } from '@chakra-ui/react';
import { IoKeyOutline } from 'react-icons/io5';
import { strings } from '@localisation';
import { AuthSpinner } from '@carafe/components';

export const Home = (): JSX.Element => {
  return (
    <Flex
      flexDir="column"
      minHeight="100vh"
      justify="center"
      align="center"
      px={{ base: '1rem', md: 'unset' }}
      width="100%"
    >
      <IoKeyOutline size="1.5rem" />

      <AuthSpinner
        title={strings.access.title}
        description={strings.access.description}
      />
    </Flex>
  );
};
