import type { GraphQLError } from 'graphql';

type GraphqlErrorWithExtensions = Omit<GraphQLError, 'extensions'> & {
  extensions: { [key: string]: any };
};

/**
 * Callback fo use wen filtering array of GraphQLError to return only those with
 * extensions defined.
 * @param gqlErrors raw GraphQLErrors
 * @returns
 */
export const hasExtensions = (
  gqlErrors: GraphQLError,
): gqlErrors is GraphqlErrorWithExtensions =>
  gqlErrors.extensions !== undefined;
