interface Props {
  isOpen: boolean;
  zIndex?: number;
}

export const Overlay = ({ isOpen, zIndex = 1 }: Props): JSX.Element => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: 'black',
      opacity: 0.32,
      zIndex,
      display: isOpen ? 'block' : 'none',
    }}
  />
);
