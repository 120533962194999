import { createIcon } from '@chakra-ui/icons';

// Microsoft required a custom svg as
// no color version is available in the
// react-icons library
export const Microsoft = createIcon({
  displayName: 'Microsoft',
  viewBox: '0 0 48 48',
  path: [
    <path key={'1'} fill="#ff5722" d="M22 22H6V6h16z" />,
    <path key={'2'} fill="#ff5722" d="M22 22H6V6h16z" />,
    <path key={'3'} fill="#ff5722" d="M22 22H6V6h16z" />,
    <path key={'4'} fill="#ff5722" d="M22 22H6V6h16z" />,
    <path key={'5'} fill="#ff5722" d="M22 22H6V6h16z" />,
    <path key={'6'} fill="#4caf50" d="M42 22H26V6h16z" />,
    <path key={'7'} fill="#ffc107" d="M42 42H26V26h16z" />,
    <path key={'8'} fill="#03a9f4" d="M22 42H6V26h16z" />,
  ],
});
