import { createClient, dedupExchange, fetchExchange } from 'urql';
import { cacheExchange } from '@urql/exchange-graphcache';
import type { Client } from 'urql';
import type { UpdatesConfig, KeyingConfig } from '@urql/exchange-graphcache';
import {
  ChangeEmailMutationVariables,
  GetProfileDocument,
  GetProfileQuery,
  UpdateAvatarUrlMutation,
} from '@generated/graphql';
import { devtoolsExchange } from '@urql/devtools';
import { getApiBase } from './getApiBase';

/**
 * Adds authorization header when available.
 * @param token JWT access token
 */
const requestOptions = (token?: string) => {
  if (!token) return {};
  const headers = { Authorization: `Bearer ${token}` };
  return { headers };
};

/**
 * After running a mutation, we need to specify which parts of the cache are
 * updated explicitly.
 */
const updates: Partial<UpdatesConfig> = {
  Mutation: {
    updateAvatarUrl: (result, _, cache) => {
      cache.updateQuery<GetProfileQuery>(
        { query: GetProfileDocument },
        (data) => {
          if (!data) return null;
          const cast = result as UpdateAvatarUrlMutation;
          data.getProfile.avatar = cast.updateAvatarUrl;
          return data;
        },
      );
    },
    removeAvatar: (_, __, cache) => {
      cache.updateQuery<GetProfileQuery>(
        { query: GetProfileDocument },
        (data) => {
          if (!data) return null;
          data.getProfile.avatar = undefined;
          return data;
        },
      );
    },
    changeEmail: (_, args, cache) => {
      const castArgs = args as ChangeEmailMutationVariables;

      cache.updateQuery<GetProfileQuery>(
        { query: GetProfileDocument },
        (data) => {
          if (!data) {
            return null;
          }
          data.getProfile.email = castArgs.data.newEmail;
          return data;
        },
      );
    },
  },
};

/**
 * The cache expects items to have either `id` or `_id`. If we have any data
 * that doesn't fit this, or need other behaviour, we can specify which property
 * to use as the unique id.
 */
const keys: KeyingConfig = {};

/**
 * Things that still need to be figured out
 * - should we use `requestPolicy: 'cache-and-network'` in the client config?
 * - how to create an introspection query that'll work with this.
 *
 * Don't use the regular `cacheExchange` from urql as it is not normalised and
 * harder to work with.
 *
 * Exchanges are basically just middleware.
 */
export const generateClient = (token?: string): Client => {
  const apiBase = getApiBase();
  const apiUrl = `${apiBase}/graphql`;
  return createClient({
    url: apiUrl,
    fetchOptions: () => requestOptions(token),
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      cacheExchange({ keys, updates }),
      fetchExchange,
    ],
  });
};
