export enum AccountManagementErrorCode {
  INVALID_EMAIL = 'INVALID_EMAIL',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_NOT_DISABLED = 'USER_NOT_DISABLED',
  USER_NOT_ENABLED = 'USER_NOT_ENABLED',
  EMAIL_REQUIRED = 'EMAIL_REQUIRED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  CANNOT_INVITE_SELF = 'CANNOT_INVITE_SELF',
  PROFILE_INCOMPLETE = 'PROFILE_INCOMPLETE',
  INVITATION_NOT_FOUND = 'INVITATION_NOT_FOUND',
  INVITATION_ALREADY_ACCEPTED = 'INVITATION_ALREADY_ACCEPTED',
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
  USER_HAS_PENDING_INVITATION = 'USER_HAS_PENDING_INVITATION',
  CLIENT_HAS_PENDING_INVITATION = 'CLIENT_HAS_PENDING_INVITATION',
  USER_HAS_EXISTING_ACCESS = 'USER_HAS_EXISTING_ACCESS',
  CLIENT_HAS_EXISTING_ACCESS = 'CLIENT_HAS_EXISTING_ACCESS',
  // owner login invitation specific errors
  COMPANY_REQUIRED = 'COMPANY_REQUIRED',
  COMPANY_NAME_REQUIRED = 'COMPANY_NAME_REQUIRED',
  BOND_NUMBER_REQUIRED = 'BOND_NUMBER_REQUIRED',
  CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
  PAYMENT_CONSENT_REQUIRED = 'PAYMENT_CONSENT_REQUIRED',
  INVOICED_PLAN_NOT_FOUND = 'INVOICED_PLAN_NOT_FOUND',
  INVOICED_NO_PRICE_DATA_FOUND = 'INVOICED_NO_PRICE_DATA_FOUND',
  INVOICED_USER_ADDON_NOT_FOUND = 'INVOICED_USER_ADDON_NOT_FOUND',
  INVOICED_INSUFFICIENT_USER_SLOTS = 'INVOICED_INSUFFICIENT_USER_SLOTS',
  INVOICED_API_ERROR = 'INVOICED_API_ERROR',
}
export const accountManagementErrorCodesObj = Object.keys(
  AccountManagementErrorCode,
).reduce((acc, curr) => {
  return {
    ...acc,
    [curr]: curr.toString(),
  };
}, {} as Record<string, string>);

export const accountManagementErrorArray = Object.values(
  AccountManagementErrorCode,
);
