import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { theme, AuthProvider } from '@carafe/components';
import { Routes, ProtectedRoutes } from '@routes';
import { Home, Profile, Unauthorized } from '@pages';
import { Layout } from '@layout';
import { generateClient } from '@config';
import { usePageView } from '@hooks';

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <AuthProvider
          generateClient={generateClient}
          onSuccessRoute={Routes.Profile}
          onFailureRoute={Routes.Unauthorized}
        >
          {children}
        </AuthProvider>
      </Router>
    </ChakraProvider>
  );
};

const AppRoutes = () => {
  usePageView();
  return (
    <Switch>
      <Route exact path={Routes.Home} component={Home} />
      <ProtectedRoutes>
        <Route path={Routes.Profile} component={Profile} />
      </ProtectedRoutes>
      <Route path={Routes.Unauthorized} component={Unauthorized} />
    </Switch>
  );
};

const App = (): JSX.Element => (
  <Providers>
    <Layout>
      <AppRoutes />
    </Layout>
  </Providers>
);

export default App;
