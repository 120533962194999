import { forwardRef, useState } from 'react';
import { Input, InputGroup, InputRightElement, Box } from '@chakra-ui/react';
import type { InputProps } from './InputTypes';
import { inputBaseStyle, inputValidStyle } from './inputBaseStyle';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';

type Props = Omit<InputProps, 'type'> & {
  canToggleVisibility?: boolean;
  isValid?: boolean;
  autoComplete?: 'new-password' | 'current-password';
};

export const PasswordInput = forwardRef<HTMLInputElement, Props>(
  ({ canToggleVisibility = true, isValid, ...rest }, ref) => {
    const onShowToggle = () => setShowPassword(!showPassword);
    const [showPassword, setShowPassword] = useState(false);

    return (
      <InputGroup size="md">
        <Input
          {...rest}
          ref={ref}
          type={showPassword ? 'text' : 'password'}
          width="100%"
          autoCapitalize="off"
          {...inputBaseStyle}
          {...(isValid ? inputValidStyle : undefined)}
        />
        {canToggleVisibility && (
          <InputRightElement>
            <Box
              cursor="pointer"
              as={showPassword ? IoEyeOutline : IoEyeOffOutline}
              onClick={onShowToggle}
            />
          </InputRightElement>
        )}
      </InputGroup>
    );
  },
);
