import { GraphQLResolveInfo } from 'graphql';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type ChangeDetailsInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ChangeEmailInput = {
  newEmail: Scalars['String'];
  password: Scalars['String'];
};

export type ChangePasswordInput = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  changeDetails: Scalars['Boolean'];
  changeEmail: Scalars['Boolean'];
  changePassword: Scalars['Boolean'];
  getProfilePhotoUploadUrl: PhotoUpload;
  removeAvatar: Scalars['Boolean'];
  updateAvatarUrl: Scalars['String'];
};


export type MutationChangeDetailsArgs = {
  data: ChangeDetailsInput;
};


export type MutationChangeEmailArgs = {
  data: ChangeEmailInput;
};


export type MutationChangePasswordArgs = {
  data: ChangePasswordInput;
};


export type MutationUpdateAvatarUrlArgs = {
  filename: Scalars['String'];
};

export type PhotoUpload = {
  __typename?: 'PhotoUpload';
  filename: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getProfile: UserProfile;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  avatar?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type ChangeDetailsMutationVariables = Exact<{
  data: ChangeDetailsInput;
}>;


export type ChangeDetailsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeDetails'>
);

export type ChangeEmailMutationVariables = Exact<{
  data: ChangeEmailInput;
}>;


export type ChangeEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeEmail'>
);

export type ChangePasswordMutationVariables = Exact<{
  data: ChangePasswordInput;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type GetProfilePhotoUploadUrlMutationVariables = Exact<{ [key: string]: never; }>;


export type GetProfilePhotoUploadUrlMutation = (
  { __typename?: 'Mutation' }
  & { getProfilePhotoUploadUrl: (
    { __typename?: 'PhotoUpload' }
    & Pick<PhotoUpload, 'filename' | 'uploadUrl'>
  ) }
);

export type UpdateAvatarUrlMutationVariables = Exact<{
  filename: Scalars['String'];
}>;


export type UpdateAvatarUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAvatarUrl'>
);

export type RemoveAvatarMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveAvatarMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeAvatar'>
);

export type GetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileQuery = (
  { __typename?: 'Query' }
  & { getProfile: (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'avatar'>
  ) }
);



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  ChangeDetailsInput: ChangeDetailsInput;
  String: ResolverTypeWrapper<Scalars['String']>;
  ChangeEmailInput: ChangeEmailInput;
  ChangePasswordInput: ChangePasswordInput;
  Mutation: ResolverTypeWrapper<{}>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  PhotoUpload: ResolverTypeWrapper<PhotoUpload>;
  Query: ResolverTypeWrapper<{}>;
  UserProfile: ResolverTypeWrapper<UserProfile>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  ChangeDetailsInput: ChangeDetailsInput;
  String: Scalars['String'];
  ChangeEmailInput: ChangeEmailInput;
  ChangePasswordInput: ChangePasswordInput;
  Mutation: {};
  Boolean: Scalars['Boolean'];
  PhotoUpload: PhotoUpload;
  Query: {};
  UserProfile: UserProfile;
  ID: Scalars['ID'];
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  changeDetails?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationChangeDetailsArgs, 'data'>>;
  changeEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationChangeEmailArgs, 'data'>>;
  changePassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationChangePasswordArgs, 'data'>>;
  getProfilePhotoUploadUrl?: Resolver<ResolversTypes['PhotoUpload'], ParentType, ContextType>;
  removeAvatar?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updateAvatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationUpdateAvatarUrlArgs, 'filename'>>;
};

export type PhotoUploadResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhotoUpload'] = ResolversParentTypes['PhotoUpload']> = {
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  getProfile?: Resolver<ResolversTypes['UserProfile'], ParentType, ContextType>;
};

export type UserProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProfile'] = ResolversParentTypes['UserProfile']> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Mutation?: MutationResolvers<ContextType>;
  PhotoUpload?: PhotoUploadResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  UserProfile?: UserProfileResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;


export const ChangeDetailsDocument = gql`
    mutation ChangeDetails($data: ChangeDetailsInput!) {
  changeDetails(data: $data)
}
    `;

export function useChangeDetailsMutation() {
  return Urql.useMutation<ChangeDetailsMutation, ChangeDetailsMutationVariables>(ChangeDetailsDocument);
};
export const ChangeEmailDocument = gql`
    mutation ChangeEmail($data: ChangeEmailInput!) {
  changeEmail(data: $data)
}
    `;

export function useChangeEmailMutation() {
  return Urql.useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(ChangeEmailDocument);
};
export const ChangePasswordDocument = gql`
    mutation ChangePassword($data: ChangePasswordInput!) {
  changePassword(data: $data)
}
    `;

export function useChangePasswordMutation() {
  return Urql.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument);
};
export const GetProfilePhotoUploadUrlDocument = gql`
    mutation GetProfilePhotoUploadUrl {
  getProfilePhotoUploadUrl {
    filename
    uploadUrl
  }
}
    `;

export function useGetProfilePhotoUploadUrlMutation() {
  return Urql.useMutation<GetProfilePhotoUploadUrlMutation, GetProfilePhotoUploadUrlMutationVariables>(GetProfilePhotoUploadUrlDocument);
};
export const UpdateAvatarUrlDocument = gql`
    mutation UpdateAvatarUrl($filename: String!) {
  updateAvatarUrl(filename: $filename)
}
    `;

export function useUpdateAvatarUrlMutation() {
  return Urql.useMutation<UpdateAvatarUrlMutation, UpdateAvatarUrlMutationVariables>(UpdateAvatarUrlDocument);
};
export const RemoveAvatarDocument = gql`
    mutation RemoveAvatar {
  removeAvatar
}
    `;

export function useRemoveAvatarMutation() {
  return Urql.useMutation<RemoveAvatarMutation, RemoveAvatarMutationVariables>(RemoveAvatarDocument);
};
export const GetProfileDocument = gql`
    query GetProfile {
  getProfile {
    id
    firstName
    lastName
    email
    phoneNumber
    avatar
  }
}
    `;

export function useGetProfileQuery(options: Omit<Urql.UseQueryArgs<GetProfileQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetProfileQuery>({ query: GetProfileDocument, ...options });
};