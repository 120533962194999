export enum CentralAuthErrorCode {
  INVALID_EMAIL = 'INVALID_EMAIL',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  FIRST_NAME_REQUIRED = 'FIRST_NAME_REQUIRED',
  LAST_NAME_REQUIRED = 'LAST_NAME_REQUIRED',
  PHONE_NUMBER_REQUIRED = 'PHONE_NUMBER_REQUIRED',
  EMAIL_IN_USE = 'EMAIL_IN_USE',
  STAFF_ACCESS_DISALLOWED = 'STAFF_ACCESS_DISALLOWED',
  INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
  PASSWORD_REQUIRED = 'PASSWORD_REQUIRED',
  PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
  PASSWORD_TOO_LONG = 'PASSWORD_TOO_LONG',
  INVITATION_NOT_FOUND = 'INVITATION_NOT_FOUND',
  INVITATION_ALREADY_ACCEPTED = 'INVITATION_ALREADY_ACCEPTED',
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
  NO_ACCESS_TO_APP = 'NO_ACCESS_TO_APP',
  UNAUTHORIZED = 'UNAUTHORIZED',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  MOBILE_SIGN_IN_FAILED = 'MOBILE_SIGN_IN_FAILED',
  USER_CANCELLED_AUTHORIZE = 'USER_CANCELLED_AUTHORIZE',
  REGISTRATION_BY_INVITE_ONLY = 'REGISTRATION_BY_INVITE_ONLY',
  INVALID_CUSTOMER_CODE = 'INVALID_CUSTOMER_CODE',
  TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  VINTRACE_SERVER_ERROR = 'VINTRACE_SERVER_ERROR',
  VINTRACE_UNKNOWN_ERROR = 'VINTRACE_UNKNOWN_ERROR',
  CONCURRENT_USER_ACTIVE = 'CONCURRENT_USER_ACTIVE',
  INVALID_PAYLOAD = 'INVALID_PAYLOAD',
  UNSUPPORTED_AUTHENTICATION_METHOD = 'UNSUPPORTED_AUTHENTICATION_METHOD',
}

export const centralAuthErrorCodesObj = Object.keys(
  CentralAuthErrorCode,
).reduce((acc, curr) => {
  return {
    ...acc,
    [curr]: curr.toString(),
  };
}, {} as Record<string, string>);

export const centralAuthErrorArray = Object.values(CentralAuthErrorCode);
