import { Provider, Client } from 'urql';
import { useHistory } from 'react-router-dom';
import { TokenExchange } from '@carafe/models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthContext } from './AuthContext';
import { useQueryString } from '../_hooks';
import { WithChildren } from '../types';

export interface AuthProviderProps {
  generateClient: (token: string) => Client;
  onSuccessRoute: string;
  onFailureRoute: string;
}

/**
 * Auth Provider to allow access to AuthContext and its state in child
 * components.
 *
 * Currently used for;
 * - protecting routes that require authentication
 * - reinstantiating the Urql client when we have a token to use
 * - hiding navigation links
 */
export const AuthProvider = ({
  children,
  generateClient,
  onSuccessRoute,
  onFailureRoute,
}: WithChildren<AuthProviderProps>): JSX.Element => {
  const [code, redirectTo] = useQueryString(['code', 'redirectTo']);
  const history = useHistory();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(true);
  const [token, setToken] = useState('');
  const [expires, setExpires] = useState(0);

  // Create a memoised instance of the Urql client, so that when we have a token
  // to use, we can create a new instance with that. This saves using the urql
  // authExhcange which is outrageous and would introduce 100+ lines of hard to
  // read code.
  const client = useMemo(() => generateClient(token), [generateClient, token]);

  /**
   * Request a new access and refresh token by consuming an existing refresh
   * token. Returns true on succcess, return value only used for a refresh
   * triggered by directly visiting React app or reopening a closed tab.
   */
  const refreshToken = useCallback(async () => {
    try {
      const { token, expires } = await refresh();
      setToken(token);
      setExpires(expires);
      setIsSignedIn(true);
      setIsSigningIn(false);
      return true;
    } catch {
      setIsSignedIn(false);
      history.push(onFailureRoute);
      return false;
    }
  }, [history, onFailureRoute]);

  useEffect(() => {
    let timer: number;

    if (expires > 0) {
      const expDate = new Date(expires);
      const expTimestamp = expDate.setMinutes(expDate.getMinutes() - 1);
      const now = new Date().getTime();
      const timeout = expTimestamp - now;
      timer = window.setTimeout(() => {
        refreshToken();
      }, timeout);
    }
    return () => clearTimeout(timer);
  }, [expires, refreshToken]);

  // Attempt to exchange the code in the query string for a JWT for use in
  // account management.
  useEffect(() => {
    async function tryAuthentication(code: string) {
      try {
        const { token, expires } = await authenticate(code);
        setToken(token);
        setExpires(expires);
        setIsSignedIn(true);
        setIsSigningIn(false);
        history.push(redirectTo ?? onSuccessRoute);
      } catch (err) {
        console.error(err);
        if (err instanceof Error) {
          history.push(`${onFailureRoute}?errorId=${err.message}`);
        } else {
          history.push(`${onFailureRoute}`);
        }
      }
    }
    if (code) {
      tryAuthentication(code);
    }
  }, [code, history, onFailureRoute, onSuccessRoute, redirectTo]);

  // If there's no code in the URL attempt to refresh the session from a refresh
  // token stored in a cookie.
  useEffect(() => {
    async function attempRefresh() {
      if (code !== null) {
        return;
      }
      const success = await refreshToken();
      const route = success ? onSuccessRoute : onFailureRoute;
      history.push(route);
    }
    attempRefresh();
  }, [code, history, onFailureRoute, onSuccessRoute, refreshToken]);

  return (
    <AuthContext.Provider value={{ isSignedIn, isSigningIn }}>
      <Provider value={client}>{children}</Provider>
    </AuthContext.Provider>
  );
};

async function authenticate(code: string) {
  const apiBase = getApiBase();
  const requestUrl = `${apiBase}/authenticate?tokenId=${code}`;
  const res = await sendRequest(requestUrl);
  return res;
}

async function refresh() {
  const apiBase = getApiBase();
  const requestUrl = `${apiBase}/refresh`;
  const res = await sendRequest(requestUrl, 'include');
  return res;
}

// This is the function that actually handles the work, we just pass in a URL
// and, in the case of refreshing user auth, set credentials such that the
// refresh token cookie will be included.
async function sendRequest(url: string, credentials?: RequestCredentials) {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials,
  });

  if (res.status !== 201) {
    const err = await res.text();
    throw new Error(err);
  }

  const token = await res.json();
  return token as TokenExchange;
}

/**
 * Get the API base URL, handles local environments. Returned URL does not
 * include a trailing slash.
 * @returns api base URL
 */
export const getApiBase = (): string => {
  const isLocal = window.location.host.includes(':3000');
  const local = `${window.location.hostname}:3000`;
  const url = isLocal ? local : window.location.hostname;
  return `https://${url}/api`;
};
