export const inputBaseStyle = {
  borderColor: 'gray.200',
  backgroundColor: 'gray.25',
  fontStyle: 'normal',
  fontWeight: 'normal',
  color: '#0F1010',
  fontSize: { base: `1rem`, md: '0.875rem' },
};

export const inputValidStyle = {
  borderColor: 'teal.500',
  borderWidth: '2px',
  boxShadow: '0 0 0 1px teal.500',
};
