export enum SiwmErrorCode {
  MICROSOFT_ID_IN_USE = 'MICROSOFT_ID_IN_USE',
  MICROSOFT_ID_NOT_FOUND = 'MICROSOFT_ID_NOT_FOUND',
}

export const siwmErrorCodesObj = Object.keys(SiwmErrorCode).reduce(
  (acc, curr) => {
    return {
      ...acc,
      [curr]: curr.toString(),
    };
  },
  {} as Record<string, string>,
);

export const siwmErrorArray = Object.values(SiwmErrorCode);
