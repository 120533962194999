import { Flex } from '@chakra-ui/react';

export const Initials = ({ initials }: { initials?: string }): JSX.Element => {
  return (
    <Flex
      width="5rem"
      height="5rem"
      alignItems="center"
      justifyContent="center"
      backgroundColor="teal.600"
      color="white"
      fontSize="2.625rem"
      fontWeight="300"
      borderRadius="50%"
    >
      <div>{initials ? initials : ''}</div>
    </Flex>
  );
};
