import { ProfileManagementErrorCode } from '@carafe/errors';
import { strings } from '@localisation';

/**
 * Check parsed error codes for at least one specific error code. Returns the
 * first match, undefined when no matches, or undefined when string doesn't
 * exist in `strings.error`.
 * @param errors the parsed error codes returned from the API
 * @param codes the error codes to check for
 */
export const messageForCodes = (
  errors: ProfileManagementErrorCode[],
  codes: ProfileManagementErrorCode[],
): string | undefined => {
  const matches = errors.filter((err) => codes.includes(err));

  if (!matches.length) {
    return undefined;
  }

  if (matches.length >= 2) {
    console.warn('Multiple codes matched, please check use');
  }

  const firstCode = matches[0];

  return strings.error[firstCode];
};
