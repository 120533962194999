import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  FormControl,
  FormErrorMessage,
  useToast,
  Flex,
} from '@chakra-ui/react';
import {
  TextInput,
  Label,
  NormalText,
  errorsContainsOneOf,
  SkeletonCard,
} from '@carafe/components';
import { ProfileManagementErrorCode } from '@carafe/errors';
import { strings } from '@localisation';
import { GetProfileQuery, useChangeDetailsMutation } from '@generated/graphql';
import { EmailForm } from './EmailForm';
import { errorCodeParser, messageForCodes } from '@errors';

type FormData = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

interface PropsWithData {
  profile: GetProfileQuery['getProfile'];
}

const DetailsFormWithData = ({ profile }: PropsWithData): JSX.Element => {
  const { firstName, lastName, phoneNumber } = profile;
  const { handleSubmit, register, errors } = useForm<FormData>({
    defaultValues: {
      firstName,
      lastName,
      phoneNumber,
    },
  });
  const [{ fetching, error: changeDetailsErrors }, changeDetails] =
    useChangeDetailsMutation();

  const toast = useToast();

  const onSubmit = (formData: FormData) => {
    changeDetails({ data: formData })
      .then(({ error }) => {
        if (error) {
          // TODO - Datadog log
          console.error('DetailsForm (urql):', { error });
          toast({
            title: strings.toast.error.title,
            description: strings.toast.error.description,
            status: 'error',
          });
          return;
        }
        toast({
          title: strings.toast.title,
          description: strings.toast.description,
          status: 'success',
        });
      })
      .catch((error) => {
        // TODO - Datadog log
        console.error('DetailsForm (catch):', { error });
      });
  };

  const changeDetailsErrorCodes = useMemo(
    () => errorCodeParser(changeDetailsErrors?.graphQLErrors),
    [changeDetailsErrors?.graphQLErrors],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <FormControl
          width={{ base: '100%', md: '48%' }}
          isInvalid={
            !!errors.firstName ||
            errorsContainsOneOf(changeDetailsErrorCodes, [
              ProfileManagementErrorCode.FIRST_NAME_REQUIRED,
            ])
          }
        >
          <Label htmlFor="firstName" isRequired>
            {strings.form.firstName}
          </Label>
          <TextInput
            name="firstName"
            id="firstName"
            ref={register({ required: strings.error.FIRST_NAME_REQUIRED })}
          />
          <FormErrorMessage fontSize="0.75rem">
            <NormalText
              text={
                messageForCodes(changeDetailsErrorCodes, [
                  ProfileManagementErrorCode.FIRST_NAME_REQUIRED,
                ]) ?? strings.error.FIRST_NAME_REQUIRED
              }
            />
          </FormErrorMessage>
        </FormControl>

        <FormControl
          mt={{ base: 4, md: 0 }}
          width={{ base: '100%', md: '48%' }}
          isInvalid={
            !!errors.lastName ||
            errorsContainsOneOf(changeDetailsErrorCodes, [
              ProfileManagementErrorCode.LAST_NAME_REQUIRED,
            ])
          }
        >
          <Label htmlFor="lastName" isRequired>
            {strings.form.lastName}
          </Label>
          <TextInput
            name="lastName"
            id="lastName"
            ref={register({ required: strings.error.LAST_NAME_REQUIRED })}
          />
          <FormErrorMessage fontSize="0.75rem">
            <NormalText
              text={
                messageForCodes(changeDetailsErrorCodes, [
                  ProfileManagementErrorCode.LAST_NAME_REQUIRED,
                ]) ?? strings.error.LAST_NAME_REQUIRED
              }
            />
          </FormErrorMessage>
        </FormControl>

        <FormControl
          mt={4}
          isInvalid={
            !!errors.phoneNumber ||
            errorsContainsOneOf(changeDetailsErrorCodes, [
              ProfileManagementErrorCode.PHONE_NUMBER_REQUIRED,
            ])
          }
          width={{ base: '100%', md: '48%' }}
        >
          <Label htmlFor="phoneNumber" isRequired>
            {strings.form.phoneNumber}
          </Label>
          <TextInput
            id="phoneNumber"
            name="phoneNumber"
            ref={register({ required: strings.error.PHONE_NUMBER_REQUIRED })}
          />
          <FormErrorMessage fontSize="0.75rem">
            <NormalText
              text={
                messageForCodes(changeDetailsErrorCodes, [
                  ProfileManagementErrorCode.PHONE_NUMBER_REQUIRED,
                ]) ?? strings.error.PHONE_NUMBER_REQUIRED
              }
            />
          </FormErrorMessage>
        </FormControl>

        <FormControl mt={4} width={{ base: '100%', md: '48%' }}>
          <Label htmlFor="">{strings.form.email}</Label>
          <EmailForm email={profile?.email} />
        </FormControl>
      </Flex>
      <FormControl pt="2.5rem" display="flex">
        <Button type="submit" ml="auto" colorScheme="teal" isLoading={fetching}>
          {strings.form.save}
        </Button>
      </FormControl>
    </form>
  );
};

interface Props {
  fetching: boolean;
  profile?: GetProfileQuery['getProfile'];
}

export const DetailsForm = ({ fetching, profile }: Props): JSX.Element => {
  if (fetching) {
    return (
      <SkeletonCard
        width="100%"
        spacing="0.5rem"
        border="0rem"
        p="0"
        skeletonHeight="4rem"
        rowCount={2}
      />
    );
  }
  if (profile) {
    return <DetailsFormWithData profile={profile} />;
  }
  // TODO this shouldn't happen, but we should institute DataDog logging and
  //    an upper ErrorBoundary to handle this case properly
  throw new Error(ProfileManagementErrorCode.USER_NOT_FOUND);
};
