import { WithChildren } from '../types/WithChildren';
import { Flex } from '@chakra-ui/layout';
import { Skeleton, SkeletonCircle } from '@chakra-ui/skeleton';

interface Props {
  rowCount?: number;
  skeletonHeight?: string | number;
  spacing?: string | number;
  borderColor?: string;
  mb?: string | number;
  p?: string | number;
  width?: string | number;
  border?: string | number;
  borderRadius?: string | number;
  overrides?: Record<number, Partial<CustomDimensions>>;
}

interface CustomDimensions {
  alignSelf: 'flex-end' | 'flex-start';
  width: string;
  mt: string;
  mb: string;
  height: string;
  circle: boolean;
}

/**
 * Skeleton loader with some customization options
 */
export const SkeletonCard = ({
  rowCount = 4,
  children,
  width,
  skeletonHeight = '1rem',
  spacing = '0.5rem',
  border = '0.125rem solid',
  borderColor = 'teal.500',
  mb = '0rem',
  p = '1rem',
  borderRadius = '0',
  overrides = {},
}: WithChildren<Props>): JSX.Element => {
  return (
    <Flex
      flexDir={'column'}
      width={{ md: width ?? '21rem' }}
      mb={mb}
      p={p}
      borderRadius="sm"
      border={border}
      borderColor={borderColor}
      gap={spacing}
    >
      {children}
      {new Array(rowCount).fill('').map((_, idx) => {
        const override = overrides[idx] ?? {};
        if (override.circle) {
          return <SkeletonCircle />;
        }
        return (
          <Skeleton
            height={skeletonHeight}
            key={idx}
            {...override}
            borderRadius={borderRadius}
          />
        );
      })}
    </Flex>
  );
};
