import React from 'react';
import { WithChildren, Navigation } from '@carafe/components';
import { Flex } from '@chakra-ui/react';
import { strings } from '@localisation';

export const Layout = ({ children }: WithChildren): JSX.Element => {
  return (
    <>
      <Navigation titleText={strings.navigation.name} showBurger={false} />
      <Flex as="main" py="1rem" maxWidth="66.25rem" width="100%" mx="auto">
        {children}
      </Flex>
    </>
  );
};
