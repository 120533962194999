import { createIcon } from '@chakra-ui/icons';

// Main Vintrace Logo
export const VintraceLogo = createIcon({
  displayName: 'VintraceLogo',
  viewBox: '0 0 30 30',
  path: [
    <path
      key={'1'}
      d="M30 15c0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15Z"
      fill="#00ADA8"
    />,
    <path
      key={'2'}
      d="M10.53 15.555H4.639a.633.633 0 0 1-.639-.63c0-.347.286-.63.639-.63h5.89c.478 0 .945-.13 1.35-.379a.644.644 0 0 1 .88.203.625.625 0 0 1-.206.867 3.87 3.87 0 0 1-2.023.569Zm11.498-1.259a.64.64 0 0 1-.632-.54.625.625 0 0 1 .121-.467 2.48 2.48 0 0 0 .51-1.512c0-1.389-1.146-2.518-2.555-2.518a.633.633 0 0 1-.638-.63c0-.347.286-.629.638-.629 2.113 0 3.834 1.695 3.834 3.777 0 .824-.265 1.608-.766 2.268a.645.645 0 0 1-.512.251Z"
      fill="#fff"
    />,
    <path
      key={'3'}
      d="M17.983 15.193a.647.647 0 0 1-.298-.072 3.763 3.763 0 0 1-2.045-3.344C15.64 9.694 17.359 8 19.472 8c.353 0 .639.282.639.63a.634.634 0 0 1-.639.629c-1.408 0-2.555 1.13-2.555 2.518a2.51 2.51 0 0 0 1.364 2.229.627.627 0 0 1-.298 1.187ZM15.002 25c-2.114 0-3.834-1.697-3.834-3.778 0-.825.265-1.609.765-2.267a.645.645 0 0 1 .894-.126c.283.208.34.603.129.882-.334.437-.51.96-.51 1.51 0 1.39 1.145 2.52 2.555 2.52.353 0 .639.282.639.63a.634.634 0 0 1-.638.629Z"
      fill="#fff"
    />,
    <path
      key={'4'}
      d="M15.002 25a.635.635 0 0 1-.64-.63c0-.348.287-.63.64-.63 1.408 0 2.554-1.13 2.554-2.518a2.47 2.47 0 0 0-.51-1.511.622.622 0 0 1 .128-.882.646.646 0 0 1 .894.126c.5.657.765 1.441.765 2.267 0 2.082-1.718 3.778-3.83 3.778ZM7.974 14.296a.64.64 0 0 1-.51-.252 3.719 3.719 0 0 1-.767-2.267C6.697 9.694 8.417 8 10.53 8c.353 0 .64.282.64.63a.634.634 0 0 1-.64.629c-1.41 0-2.555 1.13-2.555 2.518 0 .55.176 1.073.51 1.511a.624.624 0 0 1-.128.881.638.638 0 0 1-.383.127Z"
      fill="#fff"
    />,
    <path
      key={'5'}
      d="M12.018 15.193a.64.64 0 0 1-.566-.335.626.626 0 0 1 .269-.851 2.508 2.508 0 0 0 1.364-2.23c0-1.388-1.147-2.517-2.555-2.517a.634.634 0 0 1-.64-.63c0-.348.287-.63.64-.63 2.113 0 3.832 1.695 3.832 3.778a3.762 3.762 0 0 1-2.047 3.343.654.654 0 0 1-.297.072Z"
      fill="#fff"
    />,
    <path
      key={'6'}
      d="M17.557 19.962a.643.643 0 0 1-.535-.283c-.026-.037-1.05-1.456-4.46-2.583-3.942-1.295-5.024-2.924-5.135-3.107a.624.624 0 0 1 .22-.862.645.645 0 0 1 .875.215c.004.007.95 1.411 4.445 2.56 3.923 1.296 5.08 3.018 5.126 3.09a.626.626 0 0 1-.19.871.647.647 0 0 1-.346.1v-.001Z"
      fill="#fff"
    />,
    <path
      key={'7'}
      d="M12.445 19.962a.647.647 0 0 1-.536-.286.626.626 0 0 1 .011-.701c.058-.083 1.476-2.06 5.143-3.08 3.19-.889 4.428-2.572 4.44-2.59a.647.647 0 0 1 1.061.022.625.625 0 0 1-.011.697c-.059.084-1.477 2.06-5.143 3.082-3.228.899-4.426 2.568-4.439 2.586a.641.641 0 0 1-.526.27Z"
      fill="#fff"
    />,
    <path
      key={'8'}
      d="M25.36 15.555h-5.89a3.86 3.86 0 0 1-2.084-.607.625.625 0 0 1 .217-1.144.645.645 0 0 1 .48.088c.413.264.893.403 1.389.403h5.89c.353 0 .638.282.638.63 0 .348-.285.63-.64.63Z"
      fill="#fff"
    />,
  ],
});
