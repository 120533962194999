import { Text, FormLabel as ChakraFormLabel } from '@chakra-ui/react';
import type { FormLabelProps } from '@chakra-ui/react';

type Required = { htmlFor: string; isRequired?: boolean };

type Props = Omit<FormLabelProps, 'htmlFor'> & Required;

export const Label = ({
  children,
  isRequired = false,
  ...rest
}: Props): JSX.Element => (
  <ChakraFormLabel
    alignItems="center"
    pb="0.5rem"
    marginBottom="0rem"
    aria-required={isRequired}
    {...rest}
  >
    {children}
    {isRequired && <Sup />}
  </ChakraFormLabel>
);

const Sup = () => (
  <Text as="sup" color="red.500">
    &nbsp;*
  </Text>
);
